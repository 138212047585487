import React, { useState } from 'react';
import axios from 'axios';
import './login.css';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            // post to python server localhost:8000 from react server localhost:3000
            const response = await axios.post('http://localhost:8000/api/login/', {
                username,
                password
            });
            setMessage('Login successful!');
        } catch (error) {
            setMessage('Login failed. Please check your credentials.');
        }
    };

    return (
        <div className='login-form'>
            <div className='login-head'>
                <h2>Sign In</h2>
                <img src="" alt="logo"></img>
            </div>
            <form onSubmit={handleLogin}>
                <div className='cred1'>
                    <label>Username</label>
                    <input type="text" value={username} placeholder="Username" onChange={(e) => setUsername(e.target.value)} required />
                </div>
                <div className='cred2'>
                    <label>Password</label>
                    <input type="password" value={password} placeholder="Password" onChange={(e) => setPassword(e.target.value)} required />
                </div>
                <button className='submit' type="submit">Login</button>
            </form>
            {/* returns message if the value is truth */}
            {message && <p>{message}</p>}
        </div>
    );
};

export default Login;
